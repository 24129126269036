import axios from 'axios';
import { GET_BOOKINGS } from '../types';
import { setAlert } from './alert';

export const getBookings = () => async dispatch => {
    const token = localStorage.getItem('jwtToken');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };
    const url = `${process.env.REACT_APP_BE_URL}/bookings/`;

    await axios.get(url,{headers})
        .then((res) => {
            dispatch({ type: GET_BOOKINGS, payload: res.data.data });
        })
        .catch((error) => {
            dispatch(setAlert(error.message, 'error', 5000));
        })
}