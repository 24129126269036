import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    // background: theme.palette.background.default,
    background: '#FF9D1E',
    // color: theme.palette.contrastText
    color: 'black',
    marginTop: '2em'
  },
  copyright: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    fontWeight: 'bold',
    textAlign: 'center'
  },
  matrixmantra: {
    color: '#24262B',
    transition: 'color 0.3s', // Add transition for smooth color change
    '&:hover': {
      color: 'white' // Change color to red on hover
    }
  }
}));
