import axios from 'axios';
import {
  GET_LISTALLSHOWS,
  GET_LISTALLSHOW,
  TOGGLE_DIALOG,
  SELECT_LISTALLSHOWS,
  SELECT_ALL_LISTALLSHOWS,
  DELETE_LISTALLSHOW
} from '../types';
import { setAlert } from './alert';
import { setAlertInfo, setIsOpen } from './newAlert';

export const toggleDialog = () => ({ type: TOGGLE_DIALOG });

export const selectListAllShow = listAllShow => ({
  type: SELECT_LISTALLSHOWS,
  payload: listAllShow
});

export const selectAllListAllShows = () => ({ type: SELECT_ALL_LISTALLSHOWS });

//only for admins
export const getListAllShows = () => async dispatch => {
  const token = localStorage.getItem('jwtToken');
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  };
  const url = `${process.env.REACT_APP_BE_URL}/reservation/`;
  await axios.get(url, { headers })
    .then((res) => {
      dispatch({ type: GET_LISTALLSHOWS, payload: res.data.data });
    })
    .catch((error) => {
      dispatch(setAlert(error.message, 'error', 5000));
    })
}
//any one can access
export const getListAllShow = (id) => async dispatch => {
  const url = `${process.env.REACT_APP_BE_URL}/reservation/${id}`;
  axios.get(url)
    .then((res) => {
      dispatch({ type: GET_LISTALLSHOW, payload: res.data.data });
    })
    .catch((error) => {
      const alertinfo = {
        content: error?.response?.data?.error?.message,
        dialogType: 'none',
        title: 'A kind Info'
      }
      dispatch(setIsOpen(true))
      dispatch(setAlertInfo(alertinfo))
    })
}

export const deleteListAllShow = id => async dispatch => {
  const token = localStorage.getItem('jwtToken');
  const url = `${process.env.REACT_APP_BE_URL}/reservation/delete/${id}`;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
  axios.delete(url, { headers })
    .then((res) => {
      dispatch({ type: DELETE_LISTALLSHOW, payload: id });
      dispatch(setAlert('show Deleted', 'success', 5000));
      dispatch(getListAllShows());
      return { status: 'success', message: 'show Removed' };
    })
    .catch((error) => {
      dispatch(setAlert(error.message, 'error', 5000));
      return {
        status: 'error',
        message: ' show have not been deleted, try again.'
      };
    })
};

export const updateListAllShow = (listAllShow, showDateAndTime) => async (dispatch) => {
  const token = localStorage.getItem('jwtToken');
  const url = `${process.env.REACT_APP_BE_URL}/reservation/reserve/${showDateAndTime}`;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
  axios.put(url, {
    seatData: listAllShow.currentSelectedSeats
  }, { headers })
    .then((res) => {
      dispatch(setAlert('Show Updated', 'success', 5000));
      dispatch(getListAllShows());
      return { status: 'success', message: 'Show Updated' };
    })
    .catch((error) => {
      dispatch(setAlert(error?.response?.data?.message, 'error', 5000));
      dispatch(getListAllShow(showDateAndTime));
      dispatch(getListAllShows());

      return {
        status: 'error',
        message: ' Show have not been updated, try again.'
      };
    })
}