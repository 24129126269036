export const SET_SELECTED_SEATS = 'SET_SELECTED_SEATS';
export const SET_SUGGESTED_SEATS = 'SET_SUGGESTED_SEATS';
export const SET_SELECTED_CINEMA = 'SET_SELECTED_CINEMA';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const SET_SELECTED_TIME = 'SET_SELECTED_TIME';
export const SET_SELECTED_SHOWID = 'SET_SELECTED_SHOWID';
export const TOGGLE_LOGIN_POPUP = 'TOGGLE_LOGIN_POPUP';
export const SHOW_INVITATION_FORM = 'SHOW_INVITATION_FORM';
export const SET_INVITATION = 'SET_INVITATION';
export const SET_QR_CODE = 'SET_QR_CODE';
export const RESET_CHECKOUT = 'RESET_CHECKOUT';
