import {GET_GENRES, GET_LANGUAGES } from '../types';

const initialState = {
  genresData: [],
  languageData: []
};

const getGenres = (state, payload) => ({
  ...state,
  genresData: payload
});
const getLanguages = (state, payload) => ({
  ...state,
  languageData: payload
});

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_GENRES:
      return getGenres(state, payload);
    case GET_LANGUAGES:
      return getLanguages(state, payload);
    default:
      return state;
  }
};
