export default theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    // border: `1px solid ${theme.palette.common.neutral}`,
    border : '#ffba05',
    borderRadius: '25px',
    display: 'flex',
    // flexBasis: '420px',
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    width: '28em',
    height: '4em',
    margin: 'auto',

  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    fill : theme.palette.logo.fillColor
  },
  input: {
    flexGrow: 5,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  }
});
