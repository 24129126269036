import { GET_BOOKINGS } from '../types';

const initialState = {
  bookings: [],
};

const getBookings = (state, payload) => ({
  ...state,
  bookings: payload
});

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BOOKINGS:
      return getBookings(state, payload);
    default:
      return state;
  }
};
