import { ALERT_INFO, SWITCH_ALERT } from "../types";

export const setIsOpen = (isOpen) => ({
    type: SWITCH_ALERT,
    payload: isOpen
})

export const setAlertInfo = (alertInfo) => ({
    type: ALERT_INFO,
    payload: alertInfo
})