import React from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import { Navbar, Footer } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.common.white,
    height: '100%'
  },

  pad: {
    backgroundColor: '#ff9d1e',
    padding: '9px 0px 0px 1px',
    // position : 'relative'
    
  }
}));

function PublicLayout(props) {
  const classes = useStyles(props);
  const { children, withFooter = true } = props;
  return (

    <div className={classes.root}>
     
      {/* <div className={ classes.pad}/> */}
      <Navbar />
      {children}
      {withFooter && <Footer />}
    </div>
  );
}

export default PublicLayout;
